@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@layer base {
    body{
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }

}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(186, 217, 255, 0.8),
        hsla(215, 81%, 79%, 0.8)
    )!important;
}